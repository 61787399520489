import { getBaseUrl } from "shared/http";
import { activeTemplate } from "shared/settings/apiConfig";
import InterfaceService from "./InterfaceService";

class TemplateService {
    constructor() {
        this.service = InterfaceService.getService();
    }

    getActiveTemplate() {
        return this.service.get(getBaseUrl(activeTemplate));
    }
}

export default new TemplateService();