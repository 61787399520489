import axios from "axios";
import { Component, createRef } from "react";
import { connect } from "react-redux";
import { TemplateService } from "shared/services";
import RemovePreLoader from "shared/utils/PreLoader";
import { withRouter } from "shared/withRouter";

class TemplatePreview extends Component {
    constructor(props) {
        super(props);
        this.page = createRef();
        this.state = {
            templateData: {},
            pageLoading: true,
            srcDoc: '',
            previewNotSupport: true
        };
        this.templateId = null;
    }

    componentDidMount() {
        TemplateService.getActiveTemplate().then(async result => {
            try {
                if (result.data.hasOwnProperty('htmlFileUrl')) {
                    const S3Data = await axios.get(result.data.htmlFileUrl);
                    const parser = new DOMParser();
                    const dom_document = parser.parseFromString(S3Data.data, "text/html");
                    let body_element = dom_document.getElementsByTagName("body")[0];
                    let style_element = dom_document.getElementsByTagName("style")[0];
                    document.body.setAttribute('id', body_element.id);

                    this.setState({
                        templateData: result.data,
                        srcDoc: S3Data.data,
                        pageLoading: false,
                        previewNotSupport: false
                    }, () => {
                        // document.title = result.data.name;
                        RemovePreLoader();
                        document.body.innerHTML = body_element.innerHTML;

                        for (var i = 0; i < body_element.attributes.length; i++) {
                            var attr = body_element.attributes.item(i);
                            document.body.setAttribute(attr.nodeName, attr.nodeValue);
                        }
                        document.body.classList.add('overflow-auto');

                        /** Style Append */
                        const style = document.createElement('style');
                        style.async = true;
                        style.innerHTML = style_element.innerHTML;
                        document.body.appendChild(style);

                        var tmpScripts = dom_document.getElementsByTagName('script')[0];
                        if (tmpScripts && tmpScripts.innerHTML) {
                            const s = document.createElement('script');
                            s.async = true;
                            s.innerHTML = tmpScripts.innerHTML;
                            document.body.appendChild(s);
                        }

                        const popper = document.createElement('script');
                        popper.async = true;
                        popper.src = `https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js`;
                        document.body.appendChild(popper);

                        const bootstrap = document.createElement('script');
                        bootstrap.async = true;
                        bootstrap.src = `https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/js/bootstrap.min.js`;
                        document.body.appendChild(bootstrap);

                    });
                } else {
                    this.setState({
                        pageLoading: false
                    }, () => {
                        RemovePreLoader();
                    });
                }
            } catch (error) {
                this.setState({
                    pageLoading: false
                }, () => {
                    RemovePreLoader();
                });
            }

        }).catch(error => {
            this.setState({
                pageLoading: false
            }, () => {
                RemovePreLoader();
            });
        });
    }

    render() {
        return (
            <div className="w-100 h-100" ref={this.page}>
                {
                    this.state.pageLoading ?
                        <div className={`page-loading h-100`}>
                            <div className="d-flex align-items-center justify-content-center h-100">
                                <div className="spinner-border text-danger" style={{ width: '3rem', height: '3rem' }} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div> :
                        <div className="w-100 h-100">
                            {
                                this.state.previewNotSupport ?
                                    <div className="h-100 d-flex align-items-center justify-content-center">
                                        <h4 className="fw-normal mb-0">Templates not available</h4>
                                    </div> :
                                    <iframe className="w-100 h-100" srcDoc={this.state.srcDoc} title={this.state.templateData.name}></iframe>
                            }
                        </div>
                }
            </div>
        );
    }
}

export default connect(state => state)(withRouter(TemplatePreview));