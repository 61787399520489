/** React router dom */
import { BrowserRouter as Router } from 'react-router-dom';

/** Project library */
import FeatureRouter from './features/router';

/** Style */
import './App.scss';

function App() {
    return (
        <div className='h-100'>
            <Router>
                <FeatureRouter></FeatureRouter>
            </Router>
        </div>
    );
}

export default App;
