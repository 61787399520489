/** React library */
// import { useEffect } from "react";

/** Router library */
import { Route, Routes, Navigate } from "react-router-dom";

/** Pages & layout */
import { TemplatePreview } from "features/components";

const FeatureRouter = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<TemplatePreview />}></Route>
                <Route
                    path="*"
                    element={<Navigate to="/" />}
                />
            </Routes>
        </>
    );
};

export default FeatureRouter;