import { baseUrl } from "shared/settings/apiConfig";

/* combines base url with endpoint and query params and returns complete url */
export function getBaseUrl(endPoint, params = null) {
    let apiUrl = baseUrl + endPoint;

    // appends query params to the api url
    if (params && params instanceof Object && Object.keys(params).length > 0) {
        apiUrl += '?';
        Object.entries(params).forEach(([key, value]) => {
            apiUrl += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`
        });
        apiUrl = apiUrl.replace(/&$/, '');
    }

    return apiUrl;
}

/**
 * 
 * @param {boolean} auth 
 * @param {'json' | 'file' | 'multipart/form-data'} contentType 
 * @param {any} data 
 * @returns http options based on params passed else returns default options
 */
export function getHeaders(contentType = 'json', data = null) {
    const headers = {};
    let final = {};
    switch (contentType) {
        case 'json':
            headers['Content-Type'] = 'application/json';
            break;
        case 'multipart/form-data':
            headers['Content-Type'] = 'multipart/form-data';
            break;
        default:
            break;
    }

    final = { headers: headers };

    if (data) {
        final['data'] = JSON.stringify(data);
    }

    return final;
}